.hero-banner {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100vh;
  width: auto;
  height: 100vh;
  z-index: 100;
  overflow: hidden;
}

.hero-banner__image {
  position: relative;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.hero-banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 255, 255, 0.6);
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.25));
}

.hero-banner__heading {
  font-size: 3.2rem;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.hero-banner__subheading {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 2em;
}

@media only screen and (max-width: 720px) {
  .hero-banner__heading {
    font-size: 2.4rem;
  }
}

.temp-link {
  color: white;
}