.footer {
  background: #0D767D;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 48px;
  font-size: 14px;
  height: 220px;
}

.heading {
    margin-bottom: 18px;
}