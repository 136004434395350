.alert {
  height: 320px;
  background:#12A2AB;
  max-width: 840px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 48px;
}
